@import url("https://fonts.googleapis.com/css?family=Roboto:400,500&subset=cyrillic");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0;
  background: #fff;
  color: #7A8E92;
}

a {
  cursor: pointer;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 16px;
}

.intro {
  font-size: 19px;
  line-height: 1.5em;
}

small,
.small {
  font-size: 12px !important;
}

b,
strong {
  font-weight: bold !important;
}

.margin-top {
  margin-top: 10px;
}

.margin-bottom {
  margin-bottom: 15px !important;
}

.margin-left {
  margin-left: 10px;
}

.margin-right {
  margin-right: 10px;
}

.margin-right-min {
  margin-right: 5px;
}

.margin-min-vertical {
  margin: 5px 0;
}

.padding {
  padding: 15px;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.min-padding-right {
  padding-right: 5px !important;
}

.min-padding-left {
  padding-left: 5px !important;
}

em {
  font-style: italic;
}

.inline-div {
  display: inline;
}

mark,
.mark {
  color: #546877;
  background-color: #FDFAAC;
}

[draggable='true'] {
  cursor: move;
}

.dotted {
  border-style: dotted;
}

.display-inline {
  display: inline-block;
}

.relative {
  position: relative;
}

.dropzone {
  border-radius: 5px;
  border: 1px dashed #DEE7EB;
  padding: 10px;
  background: repeating-linear-gradient(-45deg, #f8fafb, #f8fafb 5px, #f2f6f9 5px, #f2f6f9 10px);
}

.blink-me {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.1;
  }
}

.wrapper {
  width: 100vw;
  height: 100vh;
}

.loader {
  position: absolute;
  z-index: 1000;
  width: 100%;
  top: 45%;
}

.terms {
  line-height: 1.5em;
}

.terms p {
  margin-bottom: 10px;
}

.terms .Doc3 {
  padding-left: 30px;
}

.terms .Doc4 {
  padding-left: 50px;
}

.terms h3 {
  margin: 0 0 15px;
}

.terms h4 {
  margin: 20px 0 15px;
}

.errorMsg {
  color: #FF4E69;
  font-size: 12px;
  margin-top: 5px;
}

.errorMsg.negative-margin {
  margin-top: -10px;
  margin-bottom: 15px;
}

.small-logo {
  max-width: 40px;
  border: 1px dotted #dee7eb;
}

.large-logo {
  max-width: 180px;
  border: 1px dotted #dee7eb;
}

h4.sub-header {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.top-logo {
  color: #204b6f;
}

.fa-rotate-180 {
  transform: rotate(180deg);
  display: inline-block;
}

.hidden {
  display: none;
}

.spinner-in-list,
.spinner-in-list:hover,
.pmc-panel>div>ul .spinner-in-list:hover,
.screen-panel>div>ul .spinner-in-list:hover,
.messenger-container .left .spinner-in-list:hover {
  border: 0 !important;
  text-align: center;
  background: none !important;
}

.dashboard-info i {
  font-size: 250px;
  color: #89BFE8;
  margin-bottom: 100px;
  opacity: 0.6;
}

.sketch-picker {
  box-shadow: none !important;
  padding: 0 !important;
}

.vertical-line {
  display: inline-block;
  width: 1px;
  border-right: 1px dotted #96ACBF;
  margin: 5px 15px;
  height: 25px;
}


/*** cropper ***/
.cropper-modal {
  background: #96ACBF !important;
}

.cropper-bg {
  background-repeat: repeat;
  border: 1px solid #dee7eb;
}

/** -------- **/

/*** formatted text ***/
.job-description-format {
  overflow: auto;
}

.job-description-format ul,
.page-section ul,
.terms ul,
.formatted-text ul {
  list-style: disc inside;
}

.job-description-format ol,
.page-section ol,
.terms ol,
.formatted-text ol {
  list-style: decimal inside;
}

.job-description-format ul li,
.job-description-format ol li,
.terms ul li,
.terms ol li,
.formatted-text ul li,
.formatted-text ol li {
  margin-bottom: 5px;
}

.formatted-text img {
  max-width: 100%;
}

.job-description-format p,
.job-description-format h1,
.job-description-format h2,
.job-description-format h3,
.job-description-format h4,
.job-description-format h5,
.job-description-format h6 {
  margin-bottom: 10px;
  min-height: 1px;
}

#preview-mode-tabs .job-description-format ul {
  border-bottom: 0;
}

#preview-mode-tabs .job-description-format {
  padding: 10px;
}

.pdf-summary ul {
  list-style: disc inside;
}

.pdf-summary ol {
  list-style: decimal inside;
}

/** -------- **/

/*** sla ***/
.sla-content p.margin-left {
  margin-bottom: 20px;
  margin-left: 20px !important;
}

.sla-content li {
  margin-top: 10px;
  margin-left: 40px;
}

.sla-content strong {
  color: #546877;
}

/** -------- **/

.panel-block {
  margin: 15px 0;
  padding: 10px 15px 15px;
  background: #fff;
  border-radius: 5px;
}

.panel-block table {
  margin-bottom: 0;
}

.panel-block h3 {
  margin: 2px 0;
  font-size: 16px;
}

td .text-overflow {
  max-width: 250px;
  display: inline-block;
  vertical-align: top;
}

.flag-icon {
  box-shadow: 0 1px 4px #aaa;
}

.pre-text {
  white-space: pre-line;
}

.progress {
  height: 10px;
  margin: 10px 0;
}

.text-overflow {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-value p,
.overflow-value,
.panel-menu-item-preview .overflow-note span {
  max-width: 30vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.activity-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid;
  background: #fff;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  line-height: 16px;
  color: #1088e2;
}

/** buttons **/
.btn {
  font-size: 17px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  border: 0;
  border-radius: 4px;
  padding: 12px 15px;
  height: 46px;
  outline: none;
}

.btn-xs,
.btn-group-xs>.btn {
  font-size: 12px;
  line-height: 1.5;
  padding: 1px 5px;
  height: auto;
}

.btn-sm,
.btn-group-sm>.btn {
  font-size: 14px;
  line-height: 1.7em;
  padding: 6px 10px;
  height: auto;
  border-radius: 4px;
}

.input-group-btn .btn-sm {
  min-height: 36px;
}

.btn-lg,
.btn-group-lg>.btn {
  border-radius: 6px;
  font-size: 18px;
  line-height: 1.3333;
  padding: 10px 16px;
  height: auto;
}

.btn-primary {
  background: #1088E2;
}

.btn-primary:hover {
  background: #086BB5;
}

.btn.btn-primary.disabled,
.btn.btn-primary[disabled] {
  background: #1088E2;
  opacity: 0.35;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary.active,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.focus {
  background: #065a98;
  box-shadow: inset 0 3px 7px rgb(0 0 0 / 40%);
}

.btn-success {
  background: #44DD96;
}

.btn-success:hover {
  background: #37C784;
}

.btn-success.disabled,
.btn.btn-success[disabled] {
  background: #44DD96;
  opacity: 0.35;
}

.btn-success:active,
.btn-success:focus,
.btn-success:active:hover,
.btn-success:active:focus,
.btn-success.active,
.btn-success.active:hover,
.btn-success.active:focus,
.btn-success.focus {
  background: #2fab71;
  box-shadow: inset 0 3px 7px rgb(0 0 0 / 40%);
}

.btn-danger {
  background: #FF4E69;
}

.btn-danger:hover {
  background: #E33F58;
}

.btn-danger.disabled,
.btn.btn-danger[disabled] {
  background: #FF4E69;
  opacity: 0.35;
}

.btn-danger:active,
.btn-danger:focus,
.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger.active,
.btn-danger.active:hover,
.btn-danger.active:focus,
.btn-danger.focus {
  background: #c1374c;
  box-shadow: inset 0 3px 7px rgb(0 0 0 / 40%);
}

.btn-default {
  background: #B2C3CB;
  color: #fff;
}

.btn-default:hover {
  background: #A1B0B7;
  color: #fff;
}

.btn-default.disabled,
.btn.btn-default[disabled] {
  background: #B2C3CB;
  color: #fff;
  opacity: 0.35;
}

.btn-default:active,
.btn-default:focus,
.btn-default:active:hover,
.btn-default:active:focus,
.btn-default.active,
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default.focus,
.open>.dropdown-toggle.btn-default,
.open>.dropdown-toggle.btn-default:hover,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:active {
  background: #8d9ea7;
  color: #fff;
  box-shadow: inset 0 3px 7px rgb(0 0 0 / 40%) !important;
}

.btn-link {
  color: #1088E2;
}

.btn-link:hover {
  color: #086BB5;
}

.btn-box-tool,
.btn-tool-box,
.contact-buttons .dropdown-toggle {
  background: none;
  color: #96ACBF;
  outline: none;
}

.btn-box-tool:focus,
.btn-box-tool:active,
.btn-tool-box:focus,
.btn-tool-box:active,
.btn-group.open .dropdown-toggle,
.contact-buttons .dropdown-toggle.btn-default {
  box-shadow: none;
}

.btn-box-tool:hover,
.btn-tool-box:hover,
.contact-buttons .dropdown-toggle.btn-default:hover {
  color: #1088E2;
}

.btn-box-tool.disabled,
.btn-box-tool[disabled],
.btn-tool-box.disabled,
.btn-tool-box[disabled],
.btn-box-tool.disabled:active,
.btn-box-tool[disabled]:active,
.btn-tool-box.disabled:active,
.btn-tool-box[disabled]:active,
.btn-box-tool.disabled:focus,
.btn-box-tool[disabled]:focus,
.btn-tool-box.disabled:focus,
.btn-tool-box[disabled]:focus,
.contact-buttons .dropdown-toggle.btn-default.disabled,
.contact-buttons .dropdown-toggle.btn-default[disabled] {
  color: #96ACBF;
  opacity: 0.35;
  background: none;
}

.btn-box-tool:active,
.btn-box-tool:focus,
.btn-box-tool:active:hover,
.btn-box-tool:active:focus,
.btn-box-tool.active,
.btn-box-tool.active:hover,
.btn-box-tool.active:focus,
.btn-box-tool.focus,
.btn-tool-box:active,
.btn-tool-box:focus,
.btn-tool-box:active:hover,
.btn-tool-box:active:focus,
.btn-tool-box.active,
.btn-tool-box.active:hover,
.btn-tool-box.active:focus,
.btn-tool-box.focus,
.contact-buttons .dropdown-toggle.btn-default:active,
.contact-buttons .dropdown-toggle.btn-default:focus,
.contact-buttons .dropdown-toggle.btn-default:active:hover,
.contact-buttons .dropdown-toggle.btn-default:active:focus,
.contact-buttons .dropdown-toggle.btn-default.active,
.contact-buttons .dropdown-toggle.btn-default.active:hover,
.contact-buttons .dropdown-toggle.btn-default.active:focus,
.contact-buttons .dropdown-toggle.btn-default.focus,
.contact-buttons .open>.dropdown-toggle.btn-default {
  color: #1088E2;
  box-shadow: none !important;
  outline: none;
  background: none;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-circle {
  border-radius: 50% !important;
}

.btn-circle .lnr-phone-handset {
  top: 2px;
  position: relative;
}

.btn-circle .lnr-envelope {
  top: 1px;
  position: relative;
  font-size: 16px;
}

.btn-circle .ll-match {
  top: 4px;
  position: relative;
  font-size: 16px;
}

.contact-buttons .dropdown-toggle {
  padding: 5px;
}

.full-screen-modal .contact-buttons {
  margin-bottom: 10px;
}

.contact-buttons,
.cv-buttons {
  display: inline-block;
  vertical-align: top;
}

.contact-buttons {
  margin-top: 15px;
}

body .btn-social {
  text-align: center;
}

.wide-btn {
  padding: 12px 25px !important;
}

.btn.close-btn {
  padding: 0;
  height: auto;
  margin: 0 0 0 5px;
  font-size: 13px;
}

body .btn-social>*:first-child {
  border-right: 0;
  bottom: auto;
  font-size: 17px;
  left: 10px;
  line-height: 34px;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 32px;
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
  margin-left: 1px;
}

.btn-group,
.btn-group-vertical,
.input-group {
  z-index: 1;
}

.btn-group.open {
  z-index: 2;
}

.btn.btn-round-small {
  border-radius: 50%;
  width: 23px;
  height: 23px;
  padding: 4px 6px;
  text-align: center;
  line-height: 1em;
}

.btn-suggestion {
  border: 1px solid #DEE7EB;
  margin: 5px;
  min-width: 60px;
}

.suggestion-wrapper .btn-suggestion {
  padding: 6px 28px;
  position: relative;
}

.suggestion-wrapper .btn-suggestion i.fa-check {
  position: absolute;
  left: 7px;
  top: calc(50% - 7px);
}

.btn-suggestion:hover {
  background: #f2f6f9;
}

.btn-suggestion:active,
.btn-suggestion:active:focus,
.btn-suggestion.active,
.btn-suggestion.active:focus {
  background: #89BFE8;
  box-shadow: none;
  border-color: #89BFE8;
  color: #fff;
}

.btn-suggestion:active:hover,
.btn-suggestion.active:hover {
  background: #73B9ED;
  box-shadow: none;
  border-color: #73B9ED;
  color: #fff;
}

.btn-suggestion:active .text-muted,
.btn-suggestion:active:hover .text-muted,
.btn-suggestion:active:focus .text-muted,
.btn-suggestion.active .text-muted,
.btn-suggestion.active:hover .text-muted,
.btn-suggestion.active:focus .text-muted {
  color: #fff !important;
}

.btn-default>.fa.fa-circle,
.btn-default>span>.fa.fa-circle {
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}

.btn-default>.fa-stack {
  width: 14px;
  height: 14px;
  line-height: 14px;
}

#user-top.affix .btn-default>.fa-stack {
  width: 13px;
  height: 13px;
  line-height: 12px;
}

.btn-default>.fa-stack .fa-stack-1x:last-child {
  font-size: 9px;
}

.action-buttons {
  background: linear-gradient(to right, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 10%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
  padding: 5px 5px 10px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.options-block .btn-box-tool:hover {
  color: #fff;
}

#user-top .media-body .btn.btn-tool-box.pull-right {
  padding-top: 0;
}

.suggestion-wrapper {
  margin-top: 20px;
  float: left;
  width: 100%;
}

.lighten span.btn-group>.btn.btn-danger:first-child {
  background: rgb(225 78 105 / 20%);
  color: rgb(225 78 105 / 100%);
  border: 1px dashed rgb(225 78 105 / 50%);
  padding: 5px 9px;
}

.lighten span.btn-group>.btn.btn-default:first-child {
  background: rgb(122 142 146 / 20%);
  color: rgb(122 142 146 / 100%);
  border: 1px dashed rgb(122 142 146 / 50%);
  padding: 5px 9px;
}

.lighten span.btn-group>.btn.btn-success:first-child {
  background: rgb(87 175 88 / 20%);
  color: rgb(87 175 88 / 100%);
  border: 1px dashed rgb(87 175 88 / 50%);
  padding: 5px 9px;
}

.btn-bottom {
  border-radius: 50%;
  float: right;
  margin-right: 20px;
  margin-top: -38px;
  position: relative;
  z-index: 10000;
  padding: 5px !important;
  height: 34px;
  width: 34px;
  opacity: 0.9;
}

.pencil-edit-btn i {
  display: inline-block;
  padding-top: 4px;
}

/** -------- **/

/*** theme ***/
h1,
h2 {
  color: #29638F;
}

h3,
h4,
h5,
h6 {
  color: #546877;
}

a {
  color: #1088E2;
}

a:hover {
  color: #086BB5;
}

.intro {
  color: #96ACBF;
}

hr {
  border-color: #DEE7EB;
}

.text-dark-blue {
  color: #1088E2;
}

.text-dark-blue.colntrol-label {
  color: #1088E2 !important;
}

.text-warning {
  color: #F8CB0B;
}

.text-muted {
  color: #96ACBF;
}

.text-danger {
  color: #FF4E69 !important;
}

.text-italic {
  font-style: italic;
}

.text-green,
.text-success {
  color: #58E2A2 !important;
}

.text-blue {
  color: #5bc0de;
}

.text-primary {
  color: #29638F;
}

.label-default,
.bg-default {
  color: #7A8E92;
  background: #F4F4F4 !important;
}

.label-grey,
.bg-grey {
  background: #96ACBF !important;
  color: #fff;
}

.text-grey {
  color: #96ACBF !important;
}

.label-teal,
.bg-teal {
  background: #39cccc !important;
  color: #fff;
}

.text-teal {
  color: #39cccc !important;
}

.label-purple,
.bg-purple {
  background: #746fd2 !important;
  color: #fff;
}

.text-purple {
  color: #746fd2 !important;
}

.label-maroon,
.bg-maroon {
  background: #D81B60 !important;
  color: #fff;
}

.text-maroon {
  color: #D81B60 !important;
}

.label-primary,
.bg-primary {
  background: #1088e2 !important;
}

.bg-warning {
  background: #F8CB0B !important;
}

.bg-danger {
  background: #FF4E69 !important;
}

.bg-info {
  background: #89bfe8 !important;
}

.bg-info-light {
  background: rgb(137 191 232 / 60%) !important;
}

.text-info {
  color: #5bc0de !important;
}

.text-info-light {
  color: #89BFE8 !important;
}

.bg-success {
  background: #58E2A2 !important;
}

.text-black {
  color: #546877;
}

#Meet {
  background: #7fc9fb;
}

#Screen {
  background: #75dcaf;
}

#Place {
  background: #889ee4;
}

#Rejected {
  background: #f79ba3;
}

.online {
  border-color: #34EF99 !important;
}

.offline {
  border-color: #BCCDDB !important;
}

/** -------- **/

/** date selector **/
.calendar-block {
  background: #fff;
  border-radius: 4px;
  width: 300px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 26%);
}

.calendar-block>div:first-child {
  border-radius: 4px 4px 0 0;
  color: #fff;
}

.switch-button {
  padding: 12px 7px;
}

.switch-button>i {
  font-size: 22px;
}

.switch-button>i.margin-left {
  margin-left: 5px !important;
}

.calendar-block>div>.switch-button:nth-child(1),
.calendar-block>div>.switch-button:nth-child(4) {
  width: 15%;
}

.calendar-block>div>.switch-button:nth-child(1) {
  border-radius: 4px 0 0;
}

.calendar-block>div>.switch-button:nth-child(4) {
  border-radius: 0 4px 0 0;
}

.calendar-block>div>.switch-button:nth-child(2) {
  width: 40%;
  border-radius: 0;
}

.calendar-block>div>.switch-button:nth-child(3) {
  width: 30%;
  border-radius: 0;
}

.calendarBody {
  background: #fff;
  border: 1px solid #DEE7EB;
  border-radius: 0 0 4px 4px;
  padding-bottom: 10px;
  color: #7A8E92;
}

.month {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding: 6px 20px;
}

.year {
  width: 25%;
  display: inline-block;
  vertical-align: top;
  padding: 12px 10px;
  text-align: center;
}

.month:hover,
.year:hover {
  color: #1088E2;
}

.day-name,
.day {
  width: 42.5px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.day>span,
.candidate-form .col-sm-8 .day>span {
  border-radius: 50%;
  display: inline-block;
  font-weight: normal;
  height: 30px;
  line-height: 2.1em;
  min-height: 0;
  padding: 0;
  width: 30px;
}

.day-name {
  color: #1088E2;
  padding: 12px 0;
}

.calendarBody .selector {
  cursor: pointer;
}

.calendar-block hr {
  margin: 0;
}

.calendar-block hr+.row .col-6:first-child {
  padding-right: 5px;
}

.calendar-block hr+.row .col-6:last-child {
  padding-left: 5px;
}

.right-calendar .calendar-block {
  right: 15px;
}

.calendar-options {
  border-top: 1px solid #DEE7EB;
  background: #E5EFF5;
  padding: 10px 15px 0;
  margin-bottom: -10px;
}

.calendar-options>li {
  padding-bottom: 10px;
  color: #1088E2;
  cursor: pointer;
}

/** -------- **/

/** forms **/
.form-group {
  margin-bottom: 15px;
}

.control-label,
.form-group>label {
  color: #96ACBF;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.2em;
  display: inline-block;
  text-transform: uppercase;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.form-group>label,
.form-group>.control-label,
.edit-user .control-label {
  padding-bottom: 6px;
}

.form-horizontal .control-label {
  padding-top: 13px;
}

.panel-block .edit-fields .form-group {
  margin-bottom: 0;
}

.candidate-form.form-horizontal .control-label {
  padding: 5px 0 0 5px;
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
  display: table-cell !important;
}

.input-group-btn {
  padding-top: 0 !important;
}

.panel-block textarea.form-control {
  padding: 8px 12px;
  height: auto;
}

.range-from span.input-group-addon span {
  left: 62px;
  position: absolute;
  z-index: 100;
}

.range-to span.input-group-addon span {
  left: 42px;
  position: absolute;
  z-index: 100;
}

.group-fields .col-4 {
  margin-top: 4px;
  margin-left: -1px;
}

.group-fields .col-4:first-child {
  margin-top: 0;
  margin-left: 0;
  padding-right: 0;
}

.group-fields .col-4:last-child {
  padding-left: 0;
}

.group-fields .col-4:nth-child(1) .form-control,
.group-fields .col-6:first-child .input-group,
.group-fields .col-7:first-child .input-group {
  border-radius: 4px 0 0 4px !important;
}

.group-fields .col-4:nth-child(2) .form-control,
.group-fields .col-6:first-child .input-group-addon,
.group-fields .col-7:first-child .input-group-addon {
  border-radius: 0 !important;
}

.group-fields .col-4:nth-child(3) .form-control,
.group-fields .col-6:last-child .Select-control,
.group-fields .col-5:last-child .Select .Select-control__control {
  border-radius: 0 4px 4px 0 !important;
}

.group-fields .col-6:first-child,
.group-fields .col-7:first-child {
  padding-right: 0;
}

.group-fields .col-6:last-child,
.group-fields .col-5:last-child {
  padding-left: 0;
  margin-left: -1px;
}

/** -------- **/

/** inputs **/
.form-control {
  border: 1px solid #DEE7EB;
  border-radius: 4px;
  padding: 5px 12px;
  height: 36px;
  color: #7A8E92;
  font-size: 14px;
  box-shadow: none;
}

input[type="radio"].form-control,
input[type="checkbox"].form-control {
  border: 0;
  box-shadow: none;
  height: auto;
  display: inline-block;
  width: auto;
  padding: 0;
  margin: 0;
}

.checkbox-custom-wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  line-height: 1em;
}

.checkbox-custom-min {
  position: relative;
  width: 14px;
  height: 14px;
  border: 1px solid #bbcede;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  background: #fff;
}

.checkbox-custom-wrapper input[type="checkbox"]:hover+.checkbox-custom-min {
  border: 1px solid #96ACBF;
}

.checkbox-custom-wrapper input[type="checkbox"]:disabled+.checkbox-custom-min {
  opacity: 0.35;
  border: 1px solid #bbcede !important;
}

.checkbox-custom {
  display: inline-block;
  vertical-align: middle;
}

.checkbox-custom-wrapper input[type="checkbox"]:checked+.checkbox-custom-min {
  background: #1088E2;
  border: 1px solid #1088E2;
}

.checkbox-custom-wrapper input[type="checkbox"]:checked:checked+.checkbox-custom-min::before {
  content: "\f00c";
  font: normal normal normal 11px/1 FontAwesome;
  position: absolute;
  color: #fff;
  left: 1px;
  top: 1px;
}

.checkbox-custom-wrapper input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 16px;
  width: 16px;
  z-index: 10;
  margin: 0;
}

.check-item-label {
  position: absolute;
  z-index: 10;
  left: -34px;
  top: -5px;
  padding: 15px;
}

.show-onhover {
  visibility: hidden;
}

#user-top:hover .show-onhover {
  visibility: visible;
}

.input-group-addon {
  padding: 6px 7px;
  border-radius: 4px;
  border: 1px solid #DEE7EB;
  background: rgb(222 231 235 / 60%);
  color: #96ACBF;
}

.input-group-addon.with-button {
  padding: 0;
  border: 0;
  height: 36px;
}

.input-group-addon.with-button .btn {
  border-radius: 0 4px 4px 0;
  height: 100%;
}

.tab-content .ace_editor.ace-github {
  width: 100% !important;
}

input[type="range"] {
  background: none;
}

input[type="date"]::-webkit-inner-spin-button {
  appearance: none;
  display: none;
}

input[type="date"]::-webkit-clear-button {
  font-size: 11px;
  color: #96ACBF;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: #96ACBF;
  cursor: pointer;
  opacity: 0.4;
}

input[type="date"]::-webkit-clear-button:hover,
input[type="date"]::-webkit-clear-button:active,
input[type="date"]::-webkit-calendar-picker-indicator:hover,
input[type="date"]::-webkit-calendar-picker-indicator:active,
input[type="date"]::-webkit-calendar-picker-indicator:focus {
  color: #1088e2;
}

input[type="date"].form-control {
  padding: 5px;
}

.field-description {
  display: none;
}

/** -------- **/

/** selects **/
.custom-select {
  display: inline-block;
  position: relative;
}

.custom-select select.form-control {
  appearance: none;
}

.custom-select::after {
  content: "\f107";
  font-family: FontAwesome;
  color: #7A8E92;
  padding: 12px;
  position: absolute;
  right: 10px;
  background: #fff;
  top: 2px;
  bottom: 2px;
  z-index: 100;
  text-align: center;
  width: 27px;
  font-size: 18px;
  pointer-events: none;
  box-sizing: border-box;
}

.custom-select+.input-group-btn {
  vertical-align: top;
}

select[multiple],
select[multiple].form-control {
  height: auto;
  max-height: 92px;
}

.form-control:focus,
.is-focused:not(.is-open)>.Select-control {
  border-color: #078dce !important;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 8px rgb(102 175 233 / 60%) !important;
}

.Select {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.Select .Select-control__control {
  border-color: #DEE7EB;
  color: #7A8E92;
  min-height: 36px;
}

.Select .Select-control__indicator.Select-control__dropdown-indicator,
.Select .Select-control__indicator.Select-control__clear-indicator {
  padding: 7px 2px;
  color: #96ACBF;
}

.Select .Select-control__indicator.Select-control__dropdown-indicator:hover,
.Select .Select-control__indicator.Select-control__dropdown-indicator:active,
.Select .Select-control__indicator.Select-control__dropdown-indicator:focus,
.Select .Select-control__control--is-focused .Select-control__indicator.Select-control__dropdown-indicator,
.Select .Select-control__control--menu-is-open .Select-control__indicator.Select-control__dropdown-indicator {
  color: #1088E2;
  outline: none;
}

.Select .Select-control__indicator.Select-control__clear-indicator:hover,
.Select .Select-control__indicator.Select-control__clear-indicator:active,
.Select .Select-control__indicator.Select-control__clear-indicator:focus,
.Select .Select-control__control--is-focused .Select-control__indicator.Select-control__clear-indicator,
.Select .Select-control__control--menu-is-open .Select-control__indicator.Select-control__clear-indicator {
  color: #FF4E69;
  outline: none;
}

.Select .Select-control__indicator-separator {
  background-color: #DEE7EB;
}

.Select .Select-control__placeholder {
  color: #c0c8cc !important;
  white-space: nowrap;
}

.Select .Select-control__single-value,
.Select .Select-control__control input {
  color: #7A8E92 !important;
}

.Select .Select-control__menu {
  border-radius: 4px !important;
  border: 1px solid #DEE7EB !important;
  background-color: #fff;
  max-height: 450px;
  z-index: 10;
  box-shadow: 0 6px 12px rgb(0 0 0 / 17.5%);
  margin: 0;
}

.Select .Select-control__option {
  margin: 0;
  padding: 10px;
  text-align: left;
  background: none;
  border-bottom: 0;
  border-radius: 0;
  color: #7A8E92;
}

.Select .Select-control__option:hover,
.Select .Select-control__option--is-focused {
  background: #f2f6f9 !important;
  color: #1088E2 !important;
}

.Select .Select-control__control.Select-control__control--is-focused,
.Select .Select-control__control:hover {
  border-color: #078dce !important;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 8px rgb(102 175 233 / 60%) !important;
}

.Select .Select-control__option.Select-control__option--is-selected:hover,
.Select .Select-control__option.Select-control__option--is-selected,
.Select .Select-control__option.Select-control__option--is-selected.Select-control__option--is-focused {
  background: #E5EFF5 !important;
  color: #1088E2 !important;
}

.Select .Select-control__multi-value {
  background-color: #e7f2fa;
}

#tags-wrapper .Select .Select-control__menu {
  margin-bottom: 60px;
}

.left-top-menu .filter .Select-control__value-container--is-multi.Select-control__value-container--has-value {
  max-height: 100px;
  overflow: auto;
  vertical-align: top;
}

.Select-value-label .media-left,
.Select-value-label .media-body p.company {
  display: none;
}

.Select-value-label .media-body .title {
  padding: 7px 0;
}

.Select.Select-control--is-disabled .Select-control__control {
  background: #f9f9f9;
}

.Select-control__option--is-disabled {
  opacity: 0.6;
}

/** -------- **/

/** dropdown **/
.dropdown-menu li {
  padding: 6px 12px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f2f6f9;
  color: #1088E2;
}

.dropdown-menu li.active,
.dropdown-menu li.active:hover {
  background: #E5EFF5;
  color: #1088E2;
}

.dropdown-menu .divider {
  padding: 0;
  margin: 5px 0;
  background-color: #DEE7EB;
}

.dropdown-menu {
  border: 1px solid #DEE7EB;
  margin-top: 1px;
}

.dropdown-menu .divider:hover {
  background: #e5e5e5;
}

.dropdown-menu .block-li.options-group {
  white-space: nowrap;
}

.dropdown-menu .block-li.options-group:last-child {
  border-top: 1px dotted #DEE7EB;
  margin-top: 5px;
  padding-top: 10px !important;
  color: #FF4E69;
}

.dropdown-menu .block-li.options-group:last-child:hover {
  background: none;
  color: #a7271d;
}

.dropdown-menu .block-li.options-group>button {
  display: block;
  padding: 0;
  text-align: left;
  width: 100%;
}

.dropdown-menu .block-li.options-group>button:active {
  outline: none;
}

.hover-dropdown a {
  position: relative;
}

.hover-dropdown .dropdown-menu>li>a,
.hover-dropdown .dropdown-menu>li>a:hover {
  color: inherit;
  background: none;
  padding: 0;
}

.hover-dropdown.contact-buttons .dropdown-menu>li>a {
  color: #7A8E92;
}

.hover-dropdown.contact-buttons .dropdown-menu>li>a:hover {
  color: #1088E2;
}

.dropdown-btn:hover,
.dropdown-btn:focus {
  cursor: pointer;
  color: #4a4a4a;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  background: none;
}

/** -------- **/

/** modal **/
.modal-backdrop {
  background-color: rgb(13 31 48);
}

.modal-backdrop.in {
  opacity: 0.6;
  background: #204B6F;
}

[aria-hidden="true"]+div .modal-backdrop {
  z-index: 1050;
}

.modal-header .close {
  margin-right: -15px;
  margin-top: -49px;
}

.close {
  color: #fff;
  float: right;
  font-size: 32px;
  font-weight: normal;
  line-height: 1;
  opacity: 1;
  text-shadow: none;
}

.close:hover,
.close:focus {
  color: rgb(172 212 240 / 100%);
}

.modal-title {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 22px;
}

.modal-dialog {
  margin: 50px auto;
  position: relative;
}

.modal-footer .pagination {
  margin: 0;
}

.modal-footer,
.modal-header {
  border-color: #DEE7EB;
  padding: 10px 15px;
}

.modal-footer {
  background: #E5EFF5;
  border-radius: 0 0 4px 4px;
}

.modal-content {
  border-radius: 4px;
  border: 0;
  box-shadow: 0 2px 5px rgb(0 0 0 / 25%) !important;
}

.full-screen-modal.fade.modal {
  padding: 0 !important;
  z-index: 1050;
}

.full-screen-modal .modal-lg {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.full-screen-modal .modal-lg .modal-content {
  border: 0;
  border-radius: 0;
  min-height: 100vh;
}

.full-screen-modal .modal-lg .modal-content .modal-body {
  padding: 0;
  height: calc(100vh - 120px);
  overflow: auto;
}

.full-screen-modal .modal-lg .modal-header .close {
  margin: 0;
  color: #7A8E92;
  opacity: 0.8;
}

.full-screen-modal .modal-lg .modal-header .close:hover {
  opacity: 1;
}

.full-screen-modal .modal-lg .modal-content .modal-footer {
  padding-right: 80px;
}

.modal-body img {
  max-width: 100%;
}

.modal-body>.panel-block {
  border-radius: 0;
}

.modal-footer .btn+.btn {
  margin-left: 10px;
}

/** popover **/
.screen-options-menu.popover {
  margin-top: 8px;
}

.popover {
  border-radius: 4px;
  border-color: #DEE7EB;
}

.popover.bottom>.arrow {
  border-bottom-color: #DEE7EB;
}

.popover.top>.arrow {
  border-top-color: #DEE7EB;
}

.popover.left>.arrow {
  border-left-color: #DEE7EB;
}

.popover.right>.arrow {
  border-right-color: #DEE7EB;
}

.screen-options-menu.popover.bottom>.arrow {
  border-bottom-color: rgb(0 0 0 / 10%);
}

.popover-content {
  color: #7A8E92;
}

#confirmPopover.popover {
  max-width: unset;
}

.popover-btn-line {
  padding: 10px 15px;
  border-top: 1px solid #DEE7EB;
  background: #E5EFF5;
  margin-bottom: -10px;
  border-radius: 0 0 4px 4px;
}

.popover.desc-popover {
  min-width: 500px;
  white-space: pre-line;
}

#valuePopoverWrapper .popover-content img {
  max-width: 100%;
}

#valuePopoverWrapper .popover-content {
  max-height: 90vh;
  overflow: auto;
}

/** -------- **/

/** tooltip **/
.tooltip-inner {
  background-color: #546877;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  border-radius: 3px;
  padding: 5px 8px;
  font-size: 12px !important;
}

.tooltip.left .tooltip-arrow {
  border-left-color: #546877;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #546877;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #546877;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #546877;
}

/** -------- **/

/** table **/
.table-striped>tbody>tr:nth-of-type(2n+1),
.table-hover>tbody>tr:hover {
  background-color: #f8fafb;
}

.table-hover>tbody>tr td.active,
.table-hover>tbody>tr:hover td.active,
.table-hover>tbody>tr.active td,
.table-hover>tbody>tr.active:hover td {
  background-color: #f2f6f9 !important;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  border-top: 1px solid #fff;
  padding: 10px;
}

.table>thead>tr>th,
.table>thead>tr>td {
  padding: 20px 10px;
}

.table>thead>tr>th .control-label {
  font-size: 12px;
}

.table.no-border>thead>tr>th,
.table.no-border>tbody>tr>th,
.table.no-border>tfoot>tr>th,
.table.no-border>thead>tr>td,
.table.no-border>tbody>tr>td,
.table.no-border>tfoot>tr>td {
  border: 0;
}

.table>thead>tr>th {
  white-space: nowrap;
  background: #fff none repeat scroll 0 0;
  border-bottom: 1px solid #DEE7EB;
}

.table {
  border-bottom: 1px solid #DEE7EB !important;
  margin-bottom: 10px;
}

.panel-block .table>tbody>tr:first-child td {
  border-top: 1px solid #DEE7EB !important;
}

.edit-fields.table>tbody>tr>td {
  vertical-align: middle;
}

.cats .table.candidate-tbl,
.cats .table.company-tbl,
.cats .table.contact-tbl,
.cats .table.job-tbl,
.cats .table.candidate-tbl>thead>tr>th,
.cats .table.company-tbl>thead>tr>th,
.cats .table.contact-tbl>thead>tr>th,
.cats .table.job-tbl>thead>tr>th {
  border-top-color: #fff !important;
}

.delete-block {
  padding: 10px 0;
  position: fixed;
  width: 25vw;
  bottom: 0;
  border-top: 1px dotted rgb(222 231 235 / 60%);
  text-align: left;
  left: 0;
  background: #204B6F;
}

.skills-table .form-group,
.skills-table .edit-user .form-group {
  margin: 0;
}

.skills-table .col-12 {
  padding: 0;
}

.skills-table.table>tbody>tr>td {
  vertical-align: middle;
}

.skills-table tbody>tr:first-child td {
  border-top: 1px solid #DEE7EB;
}

.checkbox-block td {
  padding: 5px;
}

.gap td,
.gap div {
  position: relative;
  text-align: center;
  background: #fcf8e3 !important;
  z-index: 0;
  padding: 0 !important;
}

.gap div {
  border-top: 1px dotted #DEE7EB;
}

.gap hr {
  position: absolute;
  width: 100%;
  border-style: dashed;
  margin: 0 !important;
  padding: 0;
  top: 50%;
}

.gap p {
  background: #fcf8e3;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 5px;
  font-size: 12px;
}

/** -------- **/

/** pagination **/
.pagination {
  margin: 0;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background: none;
  border: 0;
  color: #1088E2;
  box-shadow: none;
}

.pagination>li>a,
.pagination>li>span {
  color: #7A8E92;
  padding: 5px 7px;
  border: 0;
}

.pagination>li>a:hover {
  background: rgb(248 250 251);
  color: #1088E2;
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
  color: #7A8E92;
  opacity: 0.6;
}

/** -------- **/

/** tabs **/
.nav.nav-tabs {
  border: 0;
  background: #f2f6f9;
  display: flex;
  flex-flow: row nowrap;
}

.nav-tabs>li {
  flex-grow: 1;
  text-align: center;
  float: none;
  margin: 0;
}

.nav-tabs>li>a,
.nav-tabs>li>span {
  display: block;
  padding: 17px 5px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  text-align: center;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>span,
.nav-tabs>li.active>span:hover,
.nav-tabs>li.active>span:focus {
  border-bottom: 2px solid #29638F;
  color: #29638F;
  background: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.nav-tabs>li>a:hover,
.nav-tabs>li>span:hover {
  background: none;
  border-bottom: 2px solid transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
  color: #546877;
  background-color: #f2f6f9;
}

.nav>li>a:hover,
.nav>li>a:focus {
  background-color: rgb(248 250 251);
}


/** -------- **/

/** breadcrumb **/
.breadcrumb {
  margin: 0;
  background: none;
  padding: 0;
}

.breadcrumb a {
  color: #7A8E92;
}

.breadcrumb>li {
  display: inline-block;
  vertical-align: middle;
}

.breadcrumb>li+li::before {
  color: #7A8E92;
  opacity: 0.2;
  font-size: 17px;
  line-height: 1;
  content: "/ ";
  padding: 0 10px;
}

/** -------- **/

/** alerts **/
.alert-dismissable .close,
.alert-dismissible .close {
  /*** from bootstrap ***/
  top: -5px;
}

.alert-dismissable .close:hover,
.alert-dismissible .close:hover {
  /*** from bootstrap ***/
  color: inherit;
  opacity: 0.8;
}

.fixed-alert {
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 1000;
  max-width: 470px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 26%);
}

/** -------- **/

/** indicators **/
.label {
  padding: 10px 15px;
  font-size: 12px;
  margin: 2px;
}

.agent-header .label {
  padding: 6px 8px;
  font-size: 12px;
  margin: 0 0 0 10px;
  border-radius: 4px;
  font-weight: 400;
  line-height: 1em;
  cursor: pointer;
}

small.label.label-danger {
  color: #fff;
  padding: 2px 3px;
  font-size: 7px !important;
  vertical-align: top;
  margin: -9px -10px 0 -6px;
  line-height: 1em !important;
  display: inline-block;
  background: #FF4E69;
}

.dot-list {
  list-style: disc inside;
  color: #96ACBF;
}

.dot-list li {
  padding: 3px 0;
}

.dot-list li span {
  color: #7A8E92;
}

.shared-phone+span {
  margin-left: 5px;
}

.shared-phone {
  cursor: pointer;
}

.badge {
  background: #B2C3CB;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.label-clear-style,
.form-group>label.label-clear-style {
  font-weight: normal;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  color: #7A8E92;
  text-transform: none;
  font-size: 14px;
}

.red-dot {
  background: #FF4E69;
  border: 1px solid #fff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
  position: absolute;
  z-index: 4;
}

.users .media-left .red-dot {
  left: 32px;
  top: 0;
}

.small-dots {
  font-size: 4px;
  line-height: 12px;
  vertical-align: middle;
}

.forget,
#user-top .forget .text-muted {
  color: #d69399;
}

.label-overflow {
  position: relative;
  left: 0;
  right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  bottom: 0;
}

.label-overflow .label-overflow {
  max-width: 100%;
  position: relative;
  left: 0;
  right: auto;
  overflow: visible;
  display: inline;
  white-space: nowrap;
  height: 20px;
  bottom: 0;
}

.label-overflow .label-overflow .fa-stack {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}

.label-overflow .label-overflow .fa-stack .lnr-envelope {
  font-size: 17px;
  color: #d69399;
}

.label-overflow .label-overflow .fa-stack .lnr-phone-handset {
  font-size: 15px;
  top: 3px;
  color: #d69399;
}

.label-overflow .label-overflow .fa-stack .lnr-bubble {
  font-size: 16px;
  top: 2px;
  left: 2px;
  color: #d69399;
}

.label-overflow .label-overflow .fa-stack .fa-times-circle {
  font-size: 12px;
  bottom: 3px;
  width: 12px;
  right: -3px;
  left: auto;
  background: #fff;
  height: 12px;
  border-radius: 50%;
}

.label-overflow .label {
  margin: 0 2px;
  vertical-align: top;
}

.label-overflow .badge {
  vertical-align: top;
}

.label-overflow .state-line .badge {
  margin: 2px 2px 0 0 !important;
  min-height: 15px;
  min-width: 15px;
  border-radius: 50%;
  padding: 0;
}

.label-overflow .badge,
.label-overflow .state-line .badge.show-label {
  border-radius: 10px;
  padding: 3px 7px;
  margin-top: 0 !important;
}

.label-overflow .badge,
.label-overflow .state-line .badge.show-label .user-star-small {
  top: 1px;
  margin-right: 3px;
}

.label-overflow .state-line .badge span {
  display: none;
}

.label-overflow .state-line .badge.show-label span {
  display: inline;
}

.cats table .label,
.users li .label,
.user-details .label {
  padding: 0.3em 0.6em 0.4em;
  display: inline-block;
  font-weight: normal;
}

.user-status {
  border-radius: 50%;
  display: block;
  height: 111px;
  position: absolute;
  top: -3px;
  left: -3px;
  width: 111px;
  text-align: center;
  z-index: 2;
  border: 3px solid transparent;
}

/** -------- **/

/** avatars **/
.user-avatar-crop img,
.company-img-crop img {
  height: 100%;
  width: auto;
}

.user-avatar-crop,
.company-img-crop {
  width: 40px;
  height: 40px;
}

.user-avatar-crop {
  border-radius: 50%;
  overflow: hidden;
  background: #a4cae6;
  text-align: center;
  z-index: 3;
  position: relative;
}

.company-img-crop {
  overflow: hidden;
  position: relative;
  background: #a4cae6;
  border-radius: 3px;
}

.system-logo-crop {
  overflow: hidden;
  position: relative;
}

td .company-img-crop,
td .user-avatar-crop {
  width: 30px;
  height: 30px;
}

.user-details #user-top .media .company-img-crop,
.user-details #user-top .media .user-avatar-crop {
  width: 105px;
  height: 105px;
}

.candidate-avatar-wrapper {
  display: inline-block;
  vertical-align: top;
  position: relative;
  border-radius: 50%;
}

.small-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  background: #a4cae6;
}

#user-top .user-avatar-crop .options-block .btn-box-tool {
  margin-top: 40px;
  font-size: 24px;
}

.options-block .btn-box-tool {
  font-size: 18px;
}

#user-top.affix .user-avatar-crop .options-block .btn-box-tool,
#user-top #preview-header.affix .user-avatar-crop .options-block .btn-box-tool {
  margin-top: 15px;
  font-size: 18px;
}

.user-details #user-top.affix .user-avatar-crop,
.user-details #user-top.affix .company-img-crop,
.user-details #user-top .media.affix .user-avatar-crop {
  width: 60px;
  height: 60px;
}

.user-details #user-top.affix .user-status,
.user-details #user-top .affix .user-status {
  width: 66px;
  height: 66px;
}

.options-block .btn-box-tool,
.modal-body .options-block .btn-box-tool {
  margin-top: 30px;
}

.small-logo .system-logo-crop .options-block .btn-box-tool {
  margin-top: 5px;
}

.fixed-panel .user-details .company-img-crop .options-block,
.user-avatar-crop .options-block,
span+.user-avatar-crop .options-block,
.company-img-crop .options-block,
.system-logo-crop .options-block {
  display: none;
  background: rgb(32 75 111 / 80%);
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.fixed-panel .user-details .company-img-crop:hover .options-block,
.user-avatar-crop:hover .options-block,
span:hover+.user-avatar-crop .options-block,
.company-img-crop:hover .options-block,
.system-logo-crop:hover .options-block {
  display: block;
}

#user-top .user-avatar-crop .options-block {
  border-radius: 50%;
}

.modal-body .user-avatar-crop img,
.modal-body .company-img-crop img {
  max-width: none;
}

.users li .user-avatar-crop,
.users li .hanged_registration,
.users li .invited,
.users li .registering {
  width: 40px;
  height: 40px;
  margin: 0;
  left: auto;
}

.user-avatar {
  display: inline-block;
  height: 100%;
}

/** -------- **/

/** attachments **/
.attachments-form .value {
  line-height: 2.5em;
}

.attachments-form .btn-tool-box {
  position: absolute;
  bottom: -45px;
  left: 5px;
  z-index: 10;
}

.attachments-form-item {
  background: #F2F6F9;
  padding: 7px 10px;
  border-radius: 4px;
}

.attachments-form-item i {
  font-size: 16px;
  padding: 2px 0;
}

.attachments-form-item+.attachments-form-item {
  margin-top: 5px;
}

/** -------- **/

/** notes **/
.notes {
  position: relative;
  margin-bottom: 10px;
}

.notes .bubble-content {
  position: relative;
  inset: 0;
  background: #fcf8e3;
  z-index: 1;
  padding: 5px 10px 7px;
  border: 1px solid #faebcc;
  border-left-width: 3px;
}

.notes .bubble-content {
  flex: 1;
}

.notes .bubble-content.active {
  flex: 3;
}

.notes .bubble-content::before {
  content: "";
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-width: 5px;
  border-style: solid;
  display: block;
  width: 0;
  z-index: 10;
}

.notes .bubble-content:nth-child(1)::before {
  border-color: #efdaaf #f2f6f9 #f2f6f9 #efdaaf;
  background: #efdaaf;
}

.notes .message-content {
  font-size: 12px;
  display: block;
  line-height: 1.5em;
}

.notes .message-content .RichEditor-root,
.notes .bubble-content:nth-child(3) .message-content {
  font-style: normal;
}

.notes .message-content .RichEditor-editor .public-DraftEditorPlaceholder-root {
  padding: 3px 4px;
  font-size: 14px;
}

.notes .message-content>div>span {
  display: inline-block;
}

.notes textarea.form-control {
  padding: 7px 10px;
  font-size: 13px;
}

.notes .chatsend-time {
  color: #96ACBF;
  padding-left: 10px;
  font-size: 12px;
  float: right;
}

.notes .bubble-content .participant-name {
  color: #29638F;
  font-size: 14px;
  display: inline-block;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
}

.notes .vertical .bubble-content .participant-name {
  max-width: 45%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notes .bubble-content i.text-warning,
.notes .bubble-content i.text-blue,
.notes .bubble-content i.text-success {
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
  vertical-align: top;
  margin-top: 4px;
}

.notes .bubble-content .message-content>div {
  line-height: 1.2em;
}

.gradient-note-bg {
  /* FF3.6-15 */

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgb(252 248 227 / 0%) 0%, rgb(252 248 227 / 70%) 25%, rgb(252 248 227 / 100%) 100%);

  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00fcf8e3', endColorstr='#fcf8e3', GradientType=0);

  /* IE6-9 */
  padding-top: 20px;
  margin-top: -20px;
  position: relative;
}

/** -------- **/

/** view CV **/
.chatcontainer.view-cv,
.pmc-panel.view-cv,
.screen-panel.view-cv {
  margin-left: calc(90px - 60vw);
}

.chatcontainer .cv-block,
.pmc-panel .cv-block,
.screen-panel .cv-block {
  visibility: hidden;
  width: 0;
}

.chatcontainer.view-cv .cats,
.pmc-panel.view-cv .cats,
.screen-panel.view-cv .cats {
  width: calc(100vw - 90px);
}

.chatcontainer.view-cv .cv-block,
.pmc-panel.view-cv .cv-block,
.screen-panel.view-cv .cv-block {
  visibility: visible;
  width: calc(60vw - 90px);
}

.cv-block {
  border-left: 1px solid #DEE7EB;
  height: calc(100vh - 51px);
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.cv-block iframe {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
}

.over-panel {
  background: rgb(229 239 245 / 75%);
  position: fixed;
  padding: 10px;
  box-shadow: 0 1px 2px rgb(176 185 191 / 75%);
  z-index: 10;
  width: calc(60vw - 90px);
  text-align: right;
}

.doc-preview {
  height: calc(100vh - 51px);
}

.modal-body .preview-panel .doc-preview {
  height: 100vh;
}

.pdf-pages {
  position: fixed;
  z-index: 15;
  top: 62px;
  right: 22vw;
}

.modal-body .preview-panel .pdf-pages,
.entity-header .preview-panel .pdf-pages {
  background: rgb(229 239 245 / 75%);
  padding: 10px;
  box-shadow: 0 1px 2px rgb(176 185 191 / 75%);
  position: fixed;
  z-index: 15;
  top: 0;
  right: 0;
  text-align: center;
  width: 40vw;
}

.modal-body .preview-panel canvas,
.cv-block canvas {
  max-width: 100%;
}

.img-preview {
  text-align: center;
  display: flex;
  height: 100vh;
  background: #96ACBF;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.img-preview img {
  max-width: 100%;
  box-shadow: 0 6px 12px rgb(0 0 0 / 17.5%);
}

.view-cv .chatcontainer .right .write {
  padding-right: 90px;
}

/* notifications */
.notification-icon {
  display: inline-block;
  position: relative;
}

.notification-icon .lnr {
  position: absolute;
  bottom: 0;
  right: -6px;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 12px;
  padding: 3px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 16%);
}

.notification-icon .lnr-bullhorn {
  background: #58E2A2;
}

.notification-icon .lnr-hourglass,
.notification-icon .lnr-bubble {
  background: #5bc0de;
}

.notification-icon .lnr-bug {
  background: #FF4E69;
}

.notification-icon+h4 {
  margin-top: 10px;
}

.Toastify__toast-body {
  color: #7A8E92;
  font-family: 'Open Sans';
}

/* spinner */
.eva-spinner {
  position: relative;
  height: 36px;
  width: 36px;
  margin: 5px;
  display: inline-block;
  vertical-align: middle;
}

.eva-spinner.small-spinner {
  height: 18px;
  width: 18px;
  margin: 0;
}

.eva-spinner>div {
  position: absolute;
  inset: 0;
  margin: auto;
  border-radius: 50%;
}

.eva-spinner>div>div {
  animation-play-state: paused;
  border: 3px solid rgb(173 188 202 / 40%);
  border-radius: 50%;
  animation: rotate 1s infinite linear;
  border-left-color: #adbcca;
  width: 100%;
  height: 100%;
}

.eva-spinner.small-spinner>div>div {
  border-width: 2px;
  animation: rotate 400ms infinite linear;
}

.eva-spinner div div {
  animation-play-state: running;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.eva-spinner i {
  font-size: 14px;
  position: absolute;
  inset: 0;
  margin: auto;
  color: #adbcca;
  width: 14px;
  height: 14px;
  line-height: 1;
  transform: scale(0) translate3d(0, 0, 0);
}

.eva-spinner i.ll-icon {
  font-size: 16px;
  width: 16px;
  height: 16px;
}

.eva-spinner i:nth-of-type(1) {
  animation: loadicons 3s infinite ease-in-out;
}

.eva-spinner i:nth-of-type(2) {
  animation: loadicons 3s 1s infinite ease-in-out;
}

.eva-spinner i:nth-of-type(3) {
  animation: loadicons 3s 2s infinite ease-in-out;
}

@keyframes loadicons {
  0% {
    transform: scale(0) translate3d(0, 0, 0);
  }

  11% {
    transform: scale(1.2) translate3d(0, 0, 0);
  }

  22% {
    transform: scale(1) translate3d(0, 0, 0);
  }

  33% {
    transform: scale(0) translate3d(0, 0, 0);
  }
}

/** -------- **/
.mobile-header+.candidate .sticky-date {
  top: 5px;
}

/* Landscape tablets and medium desktops */
@media only screen and (width >= 992px) and (width <= 1199px) {
  html,
  body {
    min-height: 100%;
  }

  input,
  textarea {
    text-size-adjust: none;
  }

  .chatcontainer.candidate .left.mobile-left .form-login-container {
    padding: 0 !important;
  }
}

/* Portrait tablets and small desktops */
@media only screen and (width >= 769px) and (width <= 991px) {
  html,
  body {
    font-size: 14px;
    min-height: 100%;
  }

  h1 {
    font-size: 27px;
  }

  h2 {
    font-size: 19px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  .intro {
    font-size: 16px;
  }

  .control-label,
  .form-group>label {
    font-size: 11px;
  }

  input,
  textarea {
    text-size-adjust: none;
  }

  .chatcontainer.candidate .left.mobile-left .form-login-container {
    padding: 0 !important;
  }
}

/* Landscape phones and portrait tablets */
@media only screen and (width >= 576px) and (width <= 768px) {
  html,
  body {
    font-size: 14px;
    min-height: 100%;
  }

  h1 {
    font-size: 27px;
  }

  h2 {
    font-size: 19px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  .intro {
    font-size: 16px;
  }

  .control-label,
  .form-group>label {
    font-size: 11px;
  }

  .modal-dialog {
    margin: 30px auto 10px;
  }

  .mobile-header>.col-3:first-child {
    padding-right: 0;
  }

  .mobile-header>.col-6 {
    padding-right: 0;
    padding-left: 0;
    white-space: nowrap;
  }

  .mobile-header>.col-3:last-child {
    padding-left: 0;
  }

  input,
  textarea {
    text-size-adjust: none;
  }

  .chatcontainer.candidate .left.mobile-left .form-login-container {
    padding: 0 !important;
  }
}

/* Small phones */
@media only screen and (width >= 341px) and (width <= 575px) {
  html,
  body {
    font-size: 14px;
    min-height: 100%;
  }

  h1 {
    font-size: 27px;
  }

  h2 {
    font-size: 19px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  .intro {
    font-size: 16px;
  }

  .control-label,
  .form-group>label {
    font-size: 11px;
  }

  .modal-dialog {
    margin: 30px auto 10px;
  }

  .mobile-header>.col-3:first-child {
    padding-right: 0;
  }

  .mobile-header>.col-6 {
    padding-right: 0;
    padding-left: 0;
    white-space: nowrap;
  }

  .mobile-header>.col-3:last-child {
    padding-left: 0;
  }

  input,
  textarea {
    text-size-adjust: none;
  }

  .chatcontainer.candidate .left.mobile-left .form-login-container {
    padding: 0 !important;
  }

  .calendar-block {
    width: 200px;
  }

  .switch-button>i {
    font-size: 16px;
  }

  .switch-button {
    font-size: 12px;
  }

  .day-name,
  .day {
    width: 28px;
    font-size: 12px;
  }

  .day>span,
  .candidate-form .col-sm-8 .day>span {
    height: 24px;
    width: 24px;
  }

  .modal {
    padding: 0;
  }

}

/* Ultra Small phones */
@media (width <= 340px) {
  html,
  body {
    font-size: 14px;
    min-height: 100%;
  }

  h1 {
    font-size: 27px;
  }

  h2 {
    font-size: 19px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  .intro {
    font-size: 16px;
  }

  .control-label,
  .form-group>label {
    font-size: 11px;
  }

  .modal-dialog {
    margin: 30px auto 10px;
  }

  .mobile-header>.col-3:first-child {
    padding-right: 0;
  }

  .mobile-header>.col-6 {
    padding-right: 0;
    padding-left: 0;
    white-space: nowrap;
  }

  .mobile-header>.col-3:last-child {
    padding-left: 0;
  }

  input,
  textarea {
    text-size-adjust: none;
  }

  .chatcontainer.candidate .left.mobile-left .form-login-container {
    padding: 0 !important;
  }

  .calendar-block {
    width: 200px;
  }

  .switch-button>i {
    font-size: 16px;
  }

  .switch-button {
    font-size: 12px;
  }

  .day-name,
  .day {
    width: 28px;
    font-size: 12px;
  }

  .day>span,
  .candidate-form .col-sm-8 .day>span {
    height: 24px;
    width: 24px;
  }

  .modal {
    padding: 0;
  }

}

@media (height <= 794px) {
  .left-nav .navbar-nav {
    zoom: 0.87;
  }

  .left-nav.navbar-inverse .navbar-nav>li.dropdown>a span>span {
    padding-right: 4px;
  }
}

@media (height <= 677px) {
  .left-nav .navbar-nav {
    zoom: 0.78;
  }

  .left-nav.navbar-inverse .navbar-nav>li.dropdown>a span>span {
    padding-right: 4px;
  }
}

#nav-dropdown-user {
  display: flex;
  align-items: center;
}
