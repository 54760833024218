.candidate-header-container {
  padding: 2px 15px;
  font-size: 12px;
  border-bottom: 1px solid #dee7eb;
}

.candidate-header-container .breadcrumb {
  padding: 10px 0;
}

.exchange-btn {
  border-right: 1px dotted #dee7eb;
  margin: 0 10px 0 0;
  display: inline-block;
}

.exchange-btn .btn-sm {
  padding: 0;
  font-size: 12px;
  height: 20px;
  width: 32px;
  position: relative;
  margin-right: 0;
}

.exchange-btn .btn-sm .lnr-arrow-left {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.exchange-btn .btn-sm .lnr-arrow-right {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.candidate-header-content .nav > .sign-out-btn a,
.mobile-header .nav > .sign-out-btn a {
  display: inline-block;
  border-left: 1px dotted #dee7eb;
  padding: 0;
  color: #96acbf;
}

.mobile-header .nav > .sign-out-btn a {
  padding: 0 15px;
  margin: 6px 0;
}

.mobile-header .nav > .sign-out-btn .dropdown-item {
  padding: 0;
  margin: 0;
}

.candidate-header-content .nav > .sign-out-btn a > .user-avatar-crop {
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  box-shadow: none;
}

.candidate-header-content .nav > .sign-out-btn a:hover,
.candidate-header-content .nav > .sign-out-btn a:focus,
.candidate-header-content .nav > .sign-out-btn a:active,
.candidate-header-content .nav > .open.sign-out-btn a,
.candidate-header-content .nav > .open.sign-out-btn a:hover,
.candidate-header-content .nav > .open.sign-out-btn a:focus,
.candidate-header-content .nav > .open.sign-out-btn a:active,
.mobile-header .nav > .sign-out-btn a:hover,
.mobile-header .nav > .sign-out-btn a:focus,
.mobile-header .nav > .sign-out-btn a:active,
.mobile-header .nav > .open.sign-out-btn a,
.mobile-header .nav > .open.sign-out-btn a:hover,
.mobile-header .nav > .open.sign-out-btn a:focus,
.mobile-header .nav > .open.sign-out-btn a:active {
  background: none;
  color: #1088e2;
}

.candidate-header-content .nav > .sign-out-btn .dropdown-menu,
.mobile-header .nav > .sign-out-btn .dropdown-menu {
  margin-top: 2px;
  border-radius: 4px;
  left: auto;
  right: 0;
  padding: 0;
  min-width: 300px;
  z-index: 10000;
}

.candidate-header-content .nav > .sign-out-btn .dropdown-menu li,
.mobile-header .nav > .sign-out-btn .dropdown-menu li {
  padding: 0;
}

.candidate-header-content .nav > .sign-out-btn .dropdown-menu > li > a,
.mobile-header .nav > .sign-out-btn .dropdown-menu > li > a {
  padding: 0;
  color: #7a8e92;
  cursor: default;
  outline: none;
  margin: 0;
  display: block;
  border-left: 0;
}

.candidate-header-content .nav > .sign-out-btn .dropdown-menu > li:hover,
.candidate-header-content .nav > .sign-out-btn .dropdown-menu > li > a:hover,
.mobile-header .nav > .sign-out-btn .dropdown-menu > li:hover,
.mobile-header .nav > .sign-out-btn .dropdown-menu > li > a:hover {
  background: none;
}

.candidate-header-content .nav > .sign-out-btn .dropdown-menu .media,
.mobile-header .nav > .sign-out-btn .dropdown-menu .media {
  padding: 10px;
  border-bottom: 1px solid #dee7eb;
  background: #f2f6f9;
  border-radius: 4px 4px 0 0;
  border-left: 0;
}

.candidate-header-content .nav > .sign-out-btn .dropdown-menu h3,
.mobile-header .nav > .sign-out-btn .dropdown-menu h3 {
  font-size: 16px;
  margin-top: 5px;
  color: #29638f;
}

.candidate-header-content .sign-out-btn .popover-btn-line,
.mobile-header .sign-out-btn .popover-btn-line {
  margin: 0;
  margin-bottom: -5px;
}

.mobile-header .nav > .sign-out-btn .dropdown-menu {
  margin: 4px 0 0;
}

.mobile-header .sign-out-btn .dropdown-toggle > .user-avatar-crop {
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
}

.candidate-header-content .nav > .sign-out-btn .dropdown-menu .media .user-avatar-crop,
.mobile-header .nav > .sign-out-btn .dropdown-menu .media .user-avatar-crop {
  margin: 5px 0;
}
