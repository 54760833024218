:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.media {
  margin-top: 15px;
}

.media:first-child {
  margin-top: 0;
}

.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}

.media-body {
  width: 10000px;
}

.media-object {
  display: block;

}

.media-object.img-thumbnail {
  max-width: none;
}

.media-right,
.media>.pull-right {
  padding-left: 10px;
}

.media-left,
.media>.pull-left {
  padding-right: 10px;
}

.media-left,
.media-right,
.media-body {
  display: table-cell;
  vertical-align: top;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

@media (width <= 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (width >= 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
